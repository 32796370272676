import React from 'react'

import { Landing } from '../../components/common'

// Styles
import '../../styles/app.css'

const Index = ({ data, location, pageContext }) => {
    return (
        <>
            <Landing>
                <div className="container">
                    <h1>Checklist FPV</h1>
                </div>
                <form action="https://gmail.us2.list-manage.com/subscribe/post?u=5af622e2cb3d4cebbabeee1f5&amp;id=a681930cda" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>
                <div className="flex-container">
                    <div className="flex-item mt-2">
                   
                        <p>Compila il form rispondi alle domande per capire quali pezzi fanno per te.</p>
                        <p className="muted">Premendo avanti accetti di iscriverti alla nostra newsletter</p>
                    <label>Nome</label>
                    <input type='text' className='form-control' name="FNAME" id="mce-FNAME" 
                        required/>
                    <label>Email</label>
                    <input type='text' className='form-control' name="EMAIL" id="mce-EMAIL"
                        required/>
                    <input type="checkbox" value="1" name="group[69904][1]" id="mce-group[69904]-69904-0" className="hidden" checked/>
                    
            
                        
                    </div>
                    <div className="flex-item">
                        <div className="social-list-container">
                            <img src="/images/landing/checklist.svg"/>
                        </div>
                    </div>
                </div>
                <div className="flex-container">
                    <div className="flex-item mt-2">
                        {/* <p>Con la checklist di NorthFPV non ti dimeticherai più nulla!</p> */}
                        
                    </div>
                    <div className="flex-item">
                        <div className="social-list-container">
                            <button className="my-btn cta-btn" type="submit">Scarica Ora!</button>
                        </div>
                    </div>
                </div>
                </form>
            </Landing>
        </>
    )
}

export default Index;
